<template>
  <Carousel :autoplay="8000" :pauseAutoplayOnHover="true"  :wrap-around="true" v-if="$store.state.lang == 'IN'">
    <Slide v-for="slide in dummyDataIN" :key="slide.id">
      <div class="carousel__item_bahan">
        <div class="wrapper_carousel_image">
            <img :src="getImage(slide.img)" v-bind:alt="slide.img" />
        </div>
        <div class="wrapper_cnd">
            <h3 class="wrapper_carousel_name">
                {{ slide.name }}
            </h3>
            <span class="wrapper_carousel_description">
                <pre>{{ slide.description }}</pre>
            </span>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
  <Carousel :autoplay="8000" :pauseAutoplayOnHover="true"  :wrap-around="true" v-if="$store.state.lang == 'EN'">
    <Slide v-for="slide in dummyDataEN" :key="slide.id">
      <div class="carousel__item_bahan">
        <div class="wrapper_carousel_image">
            <img :src="getImage(slide.img)" v-bind:alt="slide.img" />
        </div>
        <div class="wrapper_cnd">
            <h3 class="wrapper_carousel_name">
                {{ slide.name }}
            </h3>
            <span class="wrapper_carousel_description">
                <pre>{{ slide.description }}</pre>
            </span>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'ItemMesin',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data () {
    return {
      dummyDataIN: [
        { id: 0, name: 'Mesin OFFSET', description: 'Speedmaster CD 4 warna dengan kapasitas 12000 druct / jam', img: 'IMAGE3MESINOFFSET2' },
        { id: 1, name: 'Mesin OFFSET', description: 'Speedmaster CD 4 warna dengan kapasitas 12000 druct / jam', img: 'IMAGE3MESINOFFSET' },
        { id: 3, name: 'Mesin PUNCH', description: '1. Mesin Heidelberg Varimatrix dengan kapasitas 6000 druct / jam \n2. Mesin Ijima dengan kapasitas 4000 druct / jam \n3. Mesin degel dengan kapasitas 1500 druct / jam', img: 'IMAGE3MESINPUNCH' },
        { id: 4, name: 'Mesin VARNISH', description: '1. Mesin varnish waterbase dengan kapasitas 3500 druct / jam \n2. Mesin calendering varnish dengan kapasitas 2500 druct / jam \n3. Mesin varnish Ultra Violet dengan kapasitas 3500 druct / jam', img: 'IMAGE3MESINVARNISH' },
        { id: 5, name: 'Mesin FLEXO', description: 'Mesin Flexi 4 warna dengan kapasitas 150 meter / menit', img: 'flexo' }
      ],
      dummyDataEN: [
        { id: 0, name: 'OFFSET Machine', description: 'Speedmaster is a 4-color CD with capacity 12000 druct/hour', img: 'IMAGE3MESINOFFSET2' },
        { id: 1, name: 'OFFSET Machine', description: 'Speedmaster is a 4-color CD with capacity 12000 druct/hour', img: 'IMAGE3MESINOFFSET' },
        { id: 3, name: 'PUNCH Machine', description: '1. A Heidelberg varimatrix machine with a capacity of 6000 druct/hour \n2. Ijima engine with a capacity 4000 druct /hour \n3. A degel with a capacity 1500 druct / hour', img: 'IMAGE3MESINPUNCH' },
        { id: 4, name: 'VARNISH Machine', description: '1. Varnish waterbase machine with a capacity 3500 druct/hour \n2. Counting machines with a capacity 2,500 druct/hour \n3. A uv varnish machine with a capacity of 3500 druct/hour', img: 'IMAGE3MESINVARNISH' },
        { id: 5, name: 'FLEXO Machine', description: ' A four-color flexi machine with 150 meters per minute capacity', img: 'flexo' }
      ]
    }
  },
  methods: {
    getImage (imgName) {
      var image = require.context('../../assets/image/', false, /\.jpeg$/)
      return image('./' + imgName + '.jpeg')
    }
  }
})
</script>
<style scoped>
        img{
            width: 100%;
            object-fit: contain;
            border-bottom : .5rem  solid #644627;
            border-radius: 1rem 1rem 0rem 0rem;
        }
        .wrapper_carousel_image{
            width: 100%;
            height: 20rem;
            max-height: 90vh;
            border-bottom: 0.5rem solid #644627;
            display: flex;
        }
</style>
<style lang="scss">
    :root{
        --vc-nav-background-color:#644627;
        --vc-clr-primary: #644627;
        --vc-clr-secondary: rgba(100, 70, 39, .5);
    }
    .carousel__item_bahan{
        width: 70%;
        padding-bottom: 10px;
        .wrapper_carousel_description{
            max-height: 30vh;
            overflow: auto;
            display: block;
            width: 100%;
            pre{
                font-family: LondonMM;
            }
        }
    }
    @media only screen and (max-width: 600px){
        .carousel__item_bahan{
            .wrapper_carousel_image{
                max-height: 40vh;
            }
        }
        .carousel__pagination{
            margin-left: -2.5rem;
        }
    }
</style>
