<template>
<transition name="fade" mode="out-in">
  <img v-show="bgAbout" class="bg-about" src="../assets/image/IMAGE02.jpeg">
</transition>
  <div class="about">
    <div class="about-header">
      <div class="bg-about-header"></div>
      <div class="wrapper-text-about-header">
        <h1 :class="{'text-active': iA}" > GIGIHPACK </h1>
        <h2 :class="{'text-active': iB}" >
          <span v-if="$store.state.lang == 'IN'" >Salah satu perusahaan kemasan terbaik di Indonesia. </span>
          <span v-if="$store.state.lang == 'EN'" >One of the best packaging companies in Indonesia. </span>
        </h2>
        <it-icon name="expand_more" class="about-expand-more" :class="{'animated-expand-more': iC}" outlined />
      </div>
    </div>
    <Waypoint @change="contentShow"></Waypoint>
    <div class="about-content">
      <div class="wrapper-tentang-gigihpack">
        <h1>
          <span v-if="$store.state.lang == 'IN'" >Tentang Gigihpack</span>
          <span v-if="$store.state.lang == 'EN'" >About Gigihpack</span>
        </h1>
        <br />
        <div v-if="$store.state.lang == 'IN'" >
          <p> <span>Klien</span>Kenapa harus Gigihpack ?</p>
          <p> <span>Gigihpack</span>karena Gigihpack terus berkembang menjadikan salah satu produsen kemasan sekali pakai di Indonesia.</p>
          <p> <span>Klien</span>Jaminan apa yang kami dapatkan ?</p>
          <p> <span>Gigihpack</span>Kami selalu menjamin kualitas mesin yang kami pakai untuk hasil cetak yang baik dan berkualitas dengan didukung tenaga kerja ahli dibidangnya agar selalu menghasilkan terjaminnya pas waktu, pas jumlah, pas mutu.</p>
          <p> <span>Klien</span>Bagaimana dengan bahan yang digunakan ?</p>
          <p> <span>Gigihpack</span>Kami hanya memakai bahan premium dengan pertimbangan kesadaran lingkungan dan tanggung jawab perusahaan dari setiap mitra pemasok. Bahan kami ialah bahan ramah lingkungan dan berserifikat food grade, penyempurnaan terus menerus dilakukan pada proses dan fasilitas produk untuk meningkatkan kontribusi kami terhadap kelestarian lingkungan.</p>
          <p> <span>Klien</span>Kenapa kami harus menjadi Klien Gigihpack ?</p>
          <p> <span>Gigihpack</span>Bentuk pendekatan kami kepada Klien dengan memperlakukan semua pelanggan sebagai mitra dan kami memberikan layanan untuk membantu memberi solusi yang berbeda, kami menjadi wadah bagi para pelaku bisnis seperti UMKM yang sudah/baru memulai untuk memprkenalkan brand produk agar lebih dikenal luas. </p>
        </div>
        <div v-if="$store.state.lang == 'EN'" >
          <p> <span>Client</span>Why the gigihpack ?</p>
          <p> <span>Gigihpack</span>Since gigihpack continues to grow, it makes one of the most disposable packaging manufacturers in Indonesia.</p>
          <p> <span>Client</span>What assurances do we get ?</p>
          <p> <span>Gigihpack</span>We have always guaranteed the quality of the machines we use for good printing and quality with the support of skilled workers in the field to always deliver it on time, on the amount, on the quality.</p>
          <p> <span>Client</span>What about the materials used ?</p>
          <p> <span>Gigihpack</span>We only use premium material with perceived environmental awareness and corporate responsibility from each supplier partner. Our ingredients are environmentally friendly and food grade, a continuous improvement on process and product facilities to increase our contribution to the sustainability of the environment.</p>
          <p> <span>Client</span>Why do we have to be gigihpack clients ?</p>
          <p> <span>Gigihpack</span>To approach our clients by treating all customers as partners and giving us services to help them come up with a different solution, we become vessels for business people like new umkm to introduce brand products to more widely known.</p>
        </div>
      </div>
      <div class="wapper-visi-misi wapper-visi" ref="bgvisi">
        <div class="wrapper-visi-left">
        </div>
        <div class="wrapper-visi-right">
        </div>
        <h2><span v-if="$store.state.lang == 'IN'"> VISI </span><span v-if="$store.state.lang == 'EN'" >VISION</span></h2>
        <h1>
          <span v-if="$store.state.lang == 'IN'">Menyediakan solusi berkelanjutan untuk mengembangkan pengemasan tanpa merusak alam.</span>
          <span v-if="$store.state.lang == 'EN'">Provides a sustainable solution for developing packaging without damaging nature.</span>
        </h1>
      </div>
      <div class="wrapper-mesin" >
        <div class="wrapper-mesin-left">
          <h1><span v-if="$store.state.lang == 'IN'">Mesin</span><span v-if="$store.state.lang == 'EN'">Machine</span></h1>
          <h2>
            <span v-if="$store.state.lang == 'IN'">&emsp; Setiap kemasan yang baik berawal dari mesin yang berkualitas. Kami selalu mengontrol secara detail setiap proses cetak agar setiap produk yang diterima customer dalam keadaan baik.</span>
            <span v-if="$store.state.lang == 'EN'">&emsp; Every good packaging begins with a quality engine. We control the details of every printing process so that every product customer receives is in good condition.</span>
          </h2>
        </div>
        <div class="wrapper-item-mesin">
          <item-mesin />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import ItemMesin from '../components/item/ItemMesin.vue'

export default {
  name: 'About',
  components: {
    Waypoint,
    ItemMesin
  },
  data () {
    return {
      iA: false,
      iB: false,
      iC: false,
      bgAbout: false,
      foreground: null,
      background: null,
      first: null,
      second: null,
      bgvisi: null,
      bgmisi: null
    }
  },
  mounted () {
    setTimeout(() => {
      this.iA = true
    }, 100)
    setTimeout(() => {
      this.iB = true
    }, 300)
    setTimeout(() => {
      this.iC = true
    }, 600)
    this.bgvisi = this.$refs.bgvisi
    this.bgmisi = this.$refs.bgmisi
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    openWa () {
      window.open('https://wa.me/6281383065737/?text=Hallo%20GigihPack%2C%20saya%20ada%20kebutuhan%20food%20packaging%20bisa%20dibantu%3F', '_blank')
    },
    contentShow (waypointState) {
      this.bgAbout = waypointState.going === 'IN' || waypointState.direction === 'UP'
    },
    handleScroll () {
      // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // console.log(currentScrollPosition)
      // if (currentScrollPosition <= 1596) {
      //   return
      // }
      // this.bgvisi.style.backgroundPositionY = Math.floor((window.pageYOffset - 2099) / 2) + 'px'
      // this.bgmisi.style.backgroundPositionY = Math.floor((window.pageYOffset - 2349) / 2) + 'px'
    }
  }
}
</script>
<style lang="scss">
  .about{
    position: relative;
    height: auto;
    min-height: 90vh;
    text-align: left;
    border-radius: 0.8rem 0.8rem 0 0;
    .about-header{
      position: relative;
      border-radius: 0.8rem 0.8rem 0 0;
      padding: .6rem 3rem;
      -moz-box-shadow: inset 0px -3px 3px #126e38;
      -webkit-box-shadow: inset 0px -3px 3px #126e38;
      box-shadow: inset 0px -3px 3px #126e38;
      height: 90vh;
      z-index: 1;
      overflow: hidden;
      .bg-about-header{
        position: absolute;
        background-image: url("../assets/image/image_01.jpeg");
        background-position: center center;
        background-size: cover;
        height: 100vh;
        width: 110%;
        top: 0px;
        left: -5%;
        z-index: 0;
      }
      .wrapper-text-about-header{
        position: absolute;
        background: rgba(100, 70, 39,0.5);
        height: 100vh;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 1;
        h1{
          color: #fff;
          text-shadow: 0px 1px 2px #838140;
          padding: 0rem 10rem;
          font-size: 3rem;
          font-family: "LondonTwo";
          margin: 35vh 0 0 0;
          opacity: 0;
          margin-left: 2rem;
          transition: 1s;
          overflow-wrap: break-word;
        }
        h2{
          color: #fff;
          text-shadow: 0px 1px 2px #838140;
          padding: 0rem 10rem;
          font-size: 1rem;
          letter-spacing: .3rem;
          opacity: 0;
          margin-left: 2rem;
          transition: 1s;
        }
        .about-expand-more{
          color: #fff;
          position: absolute;
          left: auto;
          right: 50%;
          bottom: 15vh;
          font-size: 3rem;
          cursor: pointer;
          opacity: 0;
        }
        .text-active{
          opacity: 1;
          margin-left: 0px;
        }
        .animated-expand-more{
          -webkit-animation: emanimation 20s linear infinite;
          animation: emanimation 1.5s linear infinite;
        }
      }
    }
    .about-content{
      position: relative;
      width: 100%;
      margin: 0;
      overflow: hidden;
      background-color: rgba(255, 255,255, .4);
      .wrapper-tentang-gigihpack{
        position: relative;
        width: 81%;
        margin: 0;
        padding: 8rem 8rem 0rem 8rem;
        h1{
          font-size: 3rem;
          font-family: LondonTwo;
          color: #fff;
          text-shadow: -1px -1px 0px #644627, 1px 1px 0px #644627;
          text-align: center;
          span {
            background: none;
            border-radius: .6rem;
            display: inline-flex;
            height: 1.5rem;
            line-height: 0px;
          }
        }
        p:nth-child(odd){
          font-size: 1.3rem;
          float: left;
          padding: 1rem;
          background-color: rgba(100, 70, 39, .8);
          background-image: linear-gradient(to bottom right, rgba(100, 70, 39, 0.1) , rgba(100, 70, 39, 0.1));
          color: #fff;
          border-radius: 0rem 1rem 1rem 1rem;
          width: 60%;
          span {
            position: absolute;
            margin-top: -2.5rem;
            margin-left: -1rem;
            background-color: rgba(100, 70, 39, 0.7);
            font-family: LondonTwo;
            border-radius: 1rem 1rem 1rem 0rem;
            padding: 0.4rem;
          }
        }
        p:nth-child(even){
          font-size: 1.3rem;
          float: right;
          padding: 1rem;
          background: rgba(23, 149, 76, .8);
          background-image: linear-gradient(to bottom right, rgba(23, 149, 76, 0.1) , rgba(23, 149, 76, 0.1));
          color: #fff;
          margin: 2rem 0rem;
          border-radius: 1rem 0rem 1rem 1rem;
          width: 60%;
          span {
            float: right;
            margin-right: -1rem;
            margin-top: -2.5rem;
            background-color: rgba(23, 149, 76, 0.7);
            font-family: LondonTwo;
            border-radius: 1rem 1rem 0rem 1rem;
            padding: 0.4rem;

          }
        }
      }
      .wapper-visi-misi{
        float: left;
        position: relative;
        width: 100%;
        min-height: 200px;
        background-attachment: fixed;
        background-position-y: -101px;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 700ms;
        overflow: hidden;
      }
      .wapper-visi{
        h2{
          position: absolute;
          left: 13%;
          top: 28%;
          font-family: LondonTwo;
          color: #fff;
          letter-spacing: 0rem;
          font-size: 2rem;
          z-index: 2;
        }
        h1{
          position: absolute;
          width: 39%;
          text-align: left;
          color: #fff;
          font-size: 1.5rem;
          margin-right: 2rem;
          margin-top: 6%;
          left: auto;
          right: 15%;
          text-shadow: 1px 1px 1px #000;
          text-transform: capitalize;
          z-index: 3;
        }
        .wrapper-visi-left{
          position: absolute;
          background-color: rgba(100, 70, 39, 1);
          background-image: linear-gradient(to top right, rgb(100, 70, 39) , rgb(191, 119, 44));
          width: 40%;
          height: 420px;
          transform: rotate(15deg);
          margin-left: -5%;
          box-shadow: 16px 20px 0px rgb(100 70 39 / 20%), 9px -10px 0px rgb(100 70 39 / 50%);
          border-radius: 1rem;
          z-index: 1;
        }
        .wrapper-visi-right{
          position: absolute;
          width: 100%;
          min-height: 100%;
          background-color: rgb(100 70 39 / 30%);
          z-index: 0;
        }
      }
      .wapper-misi{
        background-image: url("../assets/image/business-strategy-success-target-goals.jpg");
        border-radius: 0rem 0rem 1rem 1rem;
        box-shadow: 0px .8rem 0rem rgb(100, 70, 39);
        z-index: 2;
        h2{
          position: absolute;
          right: 13%;
          top: 28%;
          left: auto;
          font-family: LondonTwo;
          color: #fff;
          letter-spacing: .4rem;
          font-size: 4rem;
          z-index: 3;
        }
        h1{
          position: absolute;
          width: 50%;
          text-align: left;
          color: #fff;
          margin-left: 4rem;
          margin-top: 4%;
          text-shadow: 1px 1px 1px #000;
          text-transform: capitalize;
          z-index: 2;
        }
        .wrapper-misi-left{
          position: absolute;
          width: 100%;
          min-height: 100%;
          background-color: rgba(23, 149, 76, 0.3);
          z-index: 0;
        }
        .wrapper-misi-right{
          position: absolute;
          background-color: rgba(23, 149, 76, 1);
          background-image: linear-gradient(to bottom right, rgb(14, 207, 96) , rgb(23, 149, 76));
          width: 40%;
          height: 420px;
          left: auto;
          right: 0px;
          transform: rotate(-17deg);
          margin-right: -5%;
          box-shadow: -16px -20px 0px rgba(23, 149, 76, 0.2), -9px 10px 0px rgba(23, 149, 76, 0.5);
          border-radius: 1rem;
          z-index: 1;
        }
      }
      .wrapper-mesin{
        position: relative;
        overflow: hidden;
        width: 100%;
        background-color: #fff;
        z-index: 0;
        .header-mesin{
          max-width: 100%;
          width: 100%;
          margin-top: -7rem;
          z-index: 1;
          border-top: 1px solid #000;
          filter: drop-shadow(0px 9px 0px #838140);
        }
        h1{
          font-size: 3rem;
          font-family: LondonTwo;
          color: #fff;
          text-shadow: -1px -1px 0px #644627, 1px 1px 0px #644627;
          text-align: center;
          margin-top: 5rem;
          z-index: 9;
          position: relative;
          padding-bottom: 1rem;
          span {
            background:none;
            border-radius: 0.6rem;
            display: inline-flex;
            height: 1.5rem;
            line-height: 0px;
          }
        }
        h2{
          padding: 0rem 5rem 0rem 5rem;
        }
        .wrapper-mesin-left{
          display: inline-block;
          width: 60%;
          vertical-align: top;
        }
        .wrapper-item-mesin {
          display: inline-block;
          width: 35%;
        }
      }
    }
    .it-btn{
      float: left;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
  }
  .bg-about{
    position: fixed;
    top: -40rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
  }
  @media only screen and (max-width: 865px) {
    .bg-about{
      top: -16rem;
    }
  }
  @media only screen and (max-width: 650px) {
    .bg-about{
      top: 0rem;
      width: 165%;
    }
    .about{
      .about-header{
        .wrapper-text-about-header{
          h1{
            font-size: 2.5rem;
            padding: 0rem 3rem;
          }
          h2{
            font-size: 1rem;
            padding: 0rem 3.3rem;
          }
        }
      }
      .about-content{
        .wrapper-tentang-gigihpack{
          padding: 5rem 5rem 0rem 2rem;
          h1{
            font-size: 2rem;
          }
        }
        .wapper-visi{
          .wrapper-visi-left{
            width: 58%;
            margin-left: -44%;
          }
          h2{
            left: 7%;
            top: 31%;
            font-size: 1.5rem;
            z-index: 2;
          }
          h1{
            width: 65%;
            text-align: left;
            margin-right: -2rem;
            margin-top: 20%;
            font-size: 1rem;
          }
        }
        .wrapper-mesin{
          .wrapper-mesin-left{
            width: 100%;
            h2{
              font-size: 1.3rem;
              padding: 0rem 1rem 0rem 1rem;;
            }
          }
          .wrapper-item-mesin{
            width: 80%;
            margin-left: 10%;
          }
        }
      }
    }
  }
  @keyframes emanimation {
    0% { margin-bottom: 3vh; opacity: 0; }
    80% { margin-bottom: 0vh; opacity: 1; }
    100% { margin-bottom: 0vh; opacity: 0; }
  }
</style>
